<template>
  <div class="channels-body flex flex-col w-full h-full items-center overflow-hidden">
    <div class="flex relative w-full h-full rounded-md overflow-hidden">
      <div
        ref="channelsRef"
        class="channels-body--channels z-20 bg-surface-0 h-full w-full absolute lg:relative lg:block lg:max-w-96 lg:min-w-64 flex-grow-0 flex-shrink-0"
        :class="{ hidden: !showChannels }"
      >
        <ActivityChannels @onResize="onChannelsResize" @close="onChannelsClose" />
      </div>
      <div class="channel-body--channel overflow-hidden w-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import ActivityChannels from '@/components/Activities/ActivityChannels.vue'
import eventBus from '@/eventBus'

// ======== Ref ======== //
const channelsRef = ref(null)
// ====================== //

// ======== Data ======== //
const showChannels = ref(true)
// ====================== //

// ======== Methods ======== //
const onChannelsClose = () => {
  showChannels.value = false
}
const onChannelsResize = (size) => {
  const channels = channelsRef.value
  channels.style.width = `${size}px`
}
// ====================== //

// ======== LifeCycle ======== //
onMounted(() => {
  eventBus.$on('activityChannelsOpen', () => {
    showChannels.value = true
  })
})
onBeforeUnmount(() => {
  eventBus.$off('activityChannelsOpen')
})
// ====================== //
</script>

<style scoped></style>
